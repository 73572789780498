import React from 'react';
import safeNavigate from 'apputil/safe-navigate';
import loadable from '@loadable/component';

import { HOME_LOGGED_IN_ROUTE } from 'constants/navigation';

import { SkeletonHomeView } from 'views/home/skeleton';
import SkeletonLandingView from 'views/landing/skeleton';

import { isAuthenticated } from 'services/authentication-service';

const LazyLoginPage = loadable(() => import('suspended-page/login'));

const LoginPage = ({ location = {} }) => {
  const authenticated = isAuthenticated();

  if (authenticated) {
    safeNavigate(HOME_LOGGED_IN_ROUTE);
    return (<SkeletonHomeView />);
  }

  return (
    <LazyLoginPage fallback={<SkeletonLandingView/>} location={location}/>
  );
};

export default LoginPage;
